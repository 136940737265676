import React, { useEffect, useState } from 'react';
import chevronSvg from '../../images/assets/chevron.svg';
import searchIconSvg from '../../images/assets/search-icon.svg';
import './SideBarAccordion.css';

const SideBarAccordion = ({
  name,
  subItems,
  collapseId = '',
  onCheckboxChange,
  selectedParams,
  isCollapsedAll,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedAll);
  const [searchTerm, setSearchTerm] = useState('');

  const handleAccordionClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (category, value, checked) => {
    onCheckboxChange(category, value, checked); // Call parent method
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSubItems = subItems.filter((subItem) =>
    subItem.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setIsCollapsed(isCollapsedAll);
  }, [isCollapsedAll]);

  return (
    <div id={collapseId}>
      <h5 className="accordion-title mb-0">
        <button
          className="sidebar-accordion-button font-weight-bold justify-content-between px-2"
          type="button"
          aria-expanded={!isCollapsed}
          aria-controls={collapseId}
          onClick={handleAccordionClick}
        >
          {name === 'MGN- Product Line'
            ? 'Motor Group'
            : name === 'MGN- Frame'
            ? 'Frame'
            : name === 'MGN- HP'
            ? 'Horsepower'
            : name === 'MGN- Voltage'
            ? 'Voltage'
            : name === 'MGN- Pole/RPM'
            ? 'Speed'
            : name}
          <img
            src={chevronSvg}
            alt="Chevron"
            className="chevron-img"
            style={{
              transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s',
            }}
          />
        </button>
      </h5>

      {subItems && (
        <div id={collapseId} className={`accordion-content ${!isCollapsed ? 'open' : ''}`}>
          <form className="h6 d-flex flex-column flex-nowrap mt-2 gap-2 mx-2">
            {/* Text input to search by subitem name */}
            <div className="search-container ml-3">
              <input
                className="text-input-sidebar"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <img
                className="search-icon"
                src={searchIconSvg}
                alt="Search Icon"
              />
            </div>
            <div className="list-checkboxes d-flex flex-column gap-2">
              {filteredSubItems.map((subItem, index) => (
                <div
                  key={index}
                  className="checkbox-input-sidebar d-flex align-items-center ml-3"
                >
                  <input
                    type="checkbox"
                    checked={selectedParams[name]?.includes(subItem) || false}
                    id={`${index}-${subItem}`}
                    name={subItem}
                    onChange={(e) =>
                      handleCheckboxChange(name, subItem, e.target.checked)
                    }
                  />
                  <label
                    className="label-sidebar ml-1 mb-0 text-nowrap"
                    htmlFor={`${index}-${subItem}`}
                  >
                    {subItem.replace(/^MGN-\s?[A-Za-z]?\s?-?\s?/, '')}
                  </label>
                </div>
              ))}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SideBarAccordion;
