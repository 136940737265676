/*
* @Author: Abhi Bhatt
* @Date:   2020-07-14 10:56:04
* @Last Modified by:   abhi
* @Last Modified time: 2024-10-18 13:36:04
*/
import axios from 'axios';
import { API_URL,EXTERNAL_API_URL } from '../../constants.js';
import $ from 'jquery';

class UserService {
	async login(formFields) {
		const url = `${API_URL}/login/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url+''+formFields.email+'/'+formFields.password+'/',
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async signUp(formFields) {
		const url = `${API_URL}/signup/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		var formValue;
		$.each(formFields,function(key,value){
			if(key!=='conf_password') {
				if(key==='user_email') {
					key = 'email';	
				}
				formValue+= key+'='+value+'&';
			}
			
		});
		formValue = formValue.replace("undefined","");
		return axios({
			url:url+'?'+formValue,
			method: 'get',
			crossDomain:true,
			data: formValue,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});

	}

	async logout() {
		localStorage.clear();
		let response = {'status':200,'response':'success','message':'logout successfully.'};
		return response;
	}

	async forgotPassword(formFields) {
		console.log(formFields.email);
		const url = `${API_URL}/forget-password/${formFields.email}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async resetPassword(resetPasswordToken,formFields) {
		const url = `${API_URL}/reset-password/${resetPasswordToken}/${formFields.encrypted_password}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async confirmation(email) {
		const url = `${API_URL}/send-confirmation/${email}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async sendConfirmation(confirmationToken) {
		console.log(confirmationToken);
		const url = `${API_URL}/active-user/${confirmationToken}/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			config:{headers:headers}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async updateProfile(data) {
		const url = `${API_URL}/update-profile/`;
		const headers = {
			'Content-Type':'multipart/form-data'
		}
		const formData = new FormData();
		formData.append('user_id', data.user_id);
		if(typeof data.company !== 'undefined') {
			formData.append('company', data.company);	
		}
		if(typeof data.address !== 'undefined') {
			formData.append('address', data.address);	
		}
		if(typeof data.city !== 'undefined') {
			formData.append('city', data.city);	
		}
		if(typeof data.state !== 'undefined') {
			formData.append('state', data.state);	
		}
		if(typeof data.zip !== 'undefined') {
			formData.append('zip', data.zip);	
		}
		if(typeof data.phone !== 'undefined') {
			formData.append('phone', data.phone);	
		}
		if(typeof data.cell_phone !== 'undefined') {
			formData.append('cell_phone', data.cell_hone);	
		}
		if(typeof data.direct_phone !== 'undefined') {
			formData.append('direct_phone', data.direct_phone);	
		}
		if(typeof data.fax !== 'undefined') {
			formData.append('fax', data.fax);	
		}
		if(typeof data.company_logo !== 'undefined') {
			formData.append('company_logo', data.company_logo);	
		}
		
		return axios({
			url:url,
			method: 'post',
			crossDomain:true,
			config:{headers:headers},
			data:formData
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async getOrderHistory(data) {
		const url = `${EXTERNAL_API_URL}/get-order-history/`;
		return axios({
			url:url,
			method: 'post',
			crossDomain:true,
			data:data
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}
}
export default UserService;