/*
* @Author: Abhi Bhatt
* @Date:   2020-07-09 16:24:33
* @Last Modified by:   abhi
* @Last Modified time: 2024-10-17 19:47:49
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import StaticPagesService from './../../services/StaticPagesService/StaticPagesService';


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class ContactDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location_data:[]
		}
		this.staticPagesService = new StaticPagesService();
	}

	componentDidMount() {
		const permalink = this.props.match.params.route;
		this.staticPagesService.getStaticPage(permalink).then(request=>{
			if(request.status===200) {
				this.setState({location_data:request.data.result.static_page[0].fields})
			}
		});

	}

	render() {
		let location_data = this.state.location_data;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						{renderHTML(location_data.content)}
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default ContactDetails;