/*
* @Author: Abhi Bhatt
* @Date:   2020-06-09 18:19:25
* @Last Modified by:   abhi
* @Last Modified time: 2021-07-24 19:21:01
*/
import React from 'react';
//import {Link} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import HomeService from '../../services/HomeService/HomeService';
import MotorService from '../../services/MotorService/MotorService';
import SideBar from '../../components/SideBar/SideBar';

const renderHTML = (rawHTML: string) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner_images: [],
      media_url: '',
      home_content: [],
      fields: {},
      errors: [],
      suggestions: []
      //redirect: false
    };
    this.homeService = new HomeService();
    this.motorService = new MotorService();
    this.showMotors = this.showMotors.bind(this);
    this.hideMotors = this.hideMotors.bind(this);
    this.search = this.search.bind(this);
    this.handleSearchValidation = this.handleSearchValidation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.homeService.getHome().then(response => {
      if (response.status === 200) {
        let data = response.data;
        this.setState({ media_url: data.media_url });
        this.setState({ home_content: data.result.home_content[0].fields });
      }
    });
  }

  showMotors() {
    alert('show');
  }

  hideMotors() {
    alert('hide');
  }

  handleChange(field, e) {
    if (field === 'part_number') {
      if (e.target.value.length >= 3) {
        this.getSuggestion(e.target.value);
      }
    }
    // e.preventDefault();    
    let fields = this.state.fields;
    if (field.indexOf('data') !== -1) {
      var newField = field.split(".");
      //console.log(newField[0]);
      if (newField[0] in fields) {
        if (newField[1] in fields[newField[0]]) {
          if (newField[2] in fields[newField[0]][newField[1]]) {
            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
            fields[field] = e.target.value;
          } else {
            fields[newField[0]][newField[1]][newField[2]] = '';
            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
            fields[field] = e.target.value;
          }
        } else {
          fields[newField[0]][newField[1]] = {};
          fields[newField[0]][newField[1]][newField[2]] = '';
          fields[newField[0]][newField[1]][newField[2]] = e.target.value;
          fields[field] = e.target.value;
        }
      } else {
        fields[newField[0]] = {};
        fields[newField[0]][newField[1]] = {};
        fields[newField[0]][newField[1]][newField[2]] = '';
        fields[newField[0]][newField[1]][newField[2]] = e.target.value;
        fields[field] = e.target.value;
      }
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  }

  getSuggestion(part_number) {
    let suggestions = [];
    this.motorService.searchMotorList(part_number).then(response => {
      if (response.data.response === 'success') {
        suggestions = response.data.result;
        this.setState({ suggestions: suggestions });
      } else {
        this.setState({ suggestions: suggestions });
      }
    })
  }

  handleSearchValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["part_number"]) {
      formIsValid = false;
      errors["part_number"] = "Please enter part number.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  search(e) {
    e.preventDefault();
    if (this.handleSearchValidation()) {
      let fields = this.state.fields;
      var searchString = '';
      //if(fields.keys.length>0){
      Object.keys(fields).map(function (value, key) {
        searchString += value + '=' + fields[value] + '&';
      });
      //}
      this.setState({ searchString: searchString });
      window.location.href = '/search-motor/?' + searchString;
      //console.log(searchString);
      //alert('asf');
    }
  }

  openMotorPage(part_number) {
    window.location.href = '/motors/' + part_number + '';
  }

  renderSuggestionList() {
    let { suggestions } = this.state;
    if (suggestions.length > 0) {
      return (
        <React.Fragment>
          <ul>
            {suggestions.map((value, index) => (
              <React.Fragment key={index}>
                <li onClick={() => this.openMotorPage(value.fields.part_number)}>{value.fields.part_number}</li>
              </React.Fragment>
            ))}
          </ul>
        </React.Fragment>
      )
    }
    return null;
  }

  render() {
    const isStaging = window.location.hostname === 'techtopind.net' || window.location.hostname === 'localhost' || window.location.hostname === 'localhost:3000';
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            {isStaging && (
              <div style={{ backgroundColor: 'yellow', textAlign: 'center', padding: '10px' }}>
                STAGING /DEV/QA WEBSITE
              </div>
            )}
            <section className="search-autocomplete-form p-4">
              <div className="row">
                <div className="col-md-12">
                  <hr data-content="OR" className="hr-text" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <form className="mt-4">
                    <div className="row">
                      <div className="col-md-6 divided-line">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Find motors by part number</label>
                          </div>
                          <div className="form-group col-md-10 col-10 pr-0">
                            <input type="text" className="form-control" id="part_number" onChange={(e) => this.handleChange("part_number", e)} defaultValue={this.state.fields["part_number"]} />
                            <span style={{ color: "red" }}>{this.state.errors["part_number"]}</span>
                          </div>
                          <div className="form-group col-md-2 col-2 pl-0">
                            <i className="fas fa-search" style={{ fontSize: '15px', cursor: 'pointer' }} onClick={this.search}></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 pl-5">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Find test reports by motor series number</label>
                          </div>
                          <div className="form-group col-md-10 col-10 pr-0">
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group col-md-2 col-2 pl-0">
                            <i className="fas fa-search" style={{ fontSize: '15px' }}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
            {renderHTML(this.state.home_content.content)}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Home;
