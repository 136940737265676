import React from 'react';
import { Link } from 'react-router-dom';
import './TitleWithCircle.style.css';
import circle from '../../images/assets/circle.svg';

const TitleWithCircle = ({ linkTo, title }) => {
  return (
    <div className="title-with-circle border-bottom mt-3">
      <h6 className="title-with-circle main-container h5">
        <img src={circle} alt="circle" />
        <Link className="title-with-circle title" to={linkTo}>
          {title}
        </Link>
      </h6>
    </div>
  );
};

export default TitleWithCircle;
