/*
* @Author: Randall Vargas Li
* @Date:   2024-04-26
* @Last Modified by:   Randall Vargas Li
* @Last Modified time: 2024-04-26
*/
import axios from 'axios';
import { API_URL } from '../../constants.js';

class ProductFBService {
  async getProductFBDetail(product_number) {
    const url = `${API_URL}/get-productFB-details/${product_number}/`;
    return axios.get(url)
      .then(response => response)
      .catch(error => error.response);
  }
}

export default ProductFBService;
