/*
* @Author: Abhi Bhatt
* @Date:   2020-07-10 18:58:44
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-10-09 16:08:35
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';


//const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
            errors: {},
            error_message:'',
            signup_error_message:'',
            user_id:'',
            first_name:'',
            last_name:'',
            is_login:0,
            is_logout:0,
            success_redirect:0
		}
		this.renderLoginForm = this.renderLoginForm.bind(this);
		this.renderSignUpForm = this.renderSignUpForm.bind(this);
		this.loginForm = this.loginForm.bind(this);
		this.signUpForm = this.signUpForm.bind(this);
		this.successRedirect = this.successRedirect.bind(this);
		this.userService = new UserService();
	}

	componentDidMount() {

	}

	loginForm(e) {
		e.preventDefault();
        if(this.handleValidation()){
            $("#loginSubmit").attr("disabled", true);
            this.userService.login(this.state.fields).then(response=>{
            	$("#loginSubmit").attr("disabled", false);
            	this.setState({error_message:''});
            	if(response.status===200) {
            		//console.log(response.data);
            		if(response.data.response==='fail') {
            			this.setState({error_message:response.data.message});
            			this.setState({success_redirect:0});
            		}
            		else{
            			localStorage.setItem('techtop_user_id',response.data.result[0].pk);
            			localStorage.setItem('techtop_user_first_name',response.data.result[0].fields.first_name);
            			localStorage.setItem('techtop_user_last_name',response.data.result[0].fields.last_name);
            			localStorage.setItem('is_login',1);
            			this.setState({user_id:response.data.result[0].pk});
            			this.setState({first_name:response.data.result[0].fields.first_name});
            			this.setState({last_name:response.data.result[0].fields.last_name});
            			this.setState({is_login:1});
            			this.setState({is_logout:0});
            			this.setState({success_redirect:1});
            			window.location.reload();
            		}
            	}
            });
        }
	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

	signUpForm(e){
		e.preventDefault();
		if(this.handleSignUpValidation()) {
			$("#btnSignUp").attr("disabled", true);
            this.userService.signUp(this.state.fields).then(response=>{
            	$("#btnSignUp").attr("disabled", false);
            	this.setState({error_message:''});
            	if(response.status===200) {
            		if(response.data.response==='fail') {
            			this.setState({signup_error_message:response.data.message});
            		}
            		else{
            			$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
            			this.setState({success_message:response.data.message});
            			this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
            			/*setTimeout(function(){
            				this.setState({success_message:''});
            				$('#success_message').css('display','none');
            			}.bind(this),5000);*/
            		}
            	}
            });
		}
	}

	handleSignUpValidation() {
    	let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["user_email"]){
           formIsValid = false;
           errors["user_email"] = "Please enter email.";
           document.getElementsByTagName('div')[0].style.backgroundColor =
           $("#user_email").css("backgroundColor","#EAD0D1");
           $("#user_email").css("border-color","#96151B");
        }

        if(!fields["encrypted_password"]){
           formIsValid = false;
           errors["encrypted_password"] = "Please enter password.";
           $('#encrypted_password').css("border-color","#96151B");
           $('#encrypted_password').css("backgroundColor","#EAD0D1");
        }

        if(!fields["first_name"]){
           formIsValid = false;
           errors["first_name"] = "Please enter first name.";
           $("#first_name").css("backgroundColor","#EAD0D1");
           $("#first_name").css("border-color","#96151B");
        }

        if(!fields["last_name"]){
           formIsValid = false;
           errors["last_name"] = "Please enter last name.";
           $('#last_name').css("border-color","#96151B");
           $('#last_name').css("backgroundColor","#EAD0D1");
        }

        if(!fields["company"]){
           formIsValid = false;
           errors["company"] = "Please enter company.";
           $('#company').css("border-color","#96151B");
           $('#company').css("backgroundColor","#EAD0D1");
        }

        if(!fields["address"]){
           formIsValid = false;
           errors["address"] = "Please enter address.";
           $('#address').css("border-color","#96151B");
           $('#address').css("backgroundColor","#EAD0D1");
        }

        if(!fields["phone"]){
           formIsValid = false;
           errors["phone"] = "Please enter phone.";
           $('#phone').css("border-color","#96151B");
           $('#phone').css("backgroundColor","#EAD0D1");
        }
        if(fields['encrypted_password']!==fields['conf_password']){
        	formIsValid = false;
        	errors["conf_password"] = "Password not match.";
        	$('#conf_password').css("border-color","#96151B");
        	$('#conf_password').css("backgroundColor","#EAD0D1");
        }

        this.setState({errors: errors});
       	return formIsValid;
    }

	handleValidation() {
		let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["email"]){
           formIsValid = false;
           errors["email"] = "Please enter email.";
        }

        if(!fields["password"]){
           formIsValid = false;
           errors["password"] = "Please enter password.";
        }

        this.setState({errors: errors});
       	return formIsValid;	
	}

	handleChange(field, e){     
       // e.preventDefault();    
        let fields = this.state.fields;
        if(field.indexOf('data') !== -1)
        {
        	var newField = field.split(".");
        	//console.log(newField[0]);
        	if(newField[0] in fields)
        	{
	            if(newField[1] in fields[newField[0]])
	            {
	                if(newField[2] in fields[newField[0]][newField[1]])
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	                else
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = '';
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	            }   
	            else
	            {
	                fields[newField[0]][newField[1]] = {};  
	                fields[newField[0]][newField[1]][newField[2]] = '';
	                fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                fields[field] = e.target.value;
	            }
	        }
	        else {
	            fields[newField[0]] = {};
	            fields[newField[0]][newField[1]] = {};
	            fields[newField[0]][newField[1]][newField[2]] = '';
	            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	            fields[field] = e.target.value;
	        }
	    }
	    else
	    {
	    	fields[field] = e.target.value;
	    }
	    this.setState({fields});
	}

	renderLoginForm() {
		return(
			<React.Fragment>
				<div className="col-md-6 col-sm-6 col-lg-7 col-xl-7 pr-0">
					<div className="black-bg h-100">
						<h3>Sign in</h3>
						<div className="row">
							<div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 col-12">
								<form onSubmit= {this.loginForm.bind(this)} className="login-form" id="sign_up_user">
									<span style={{color: "red"}}>{this.state.error_message}</span>
									<div className="form-group">
										<input type="email" className="form-control" name="email-address" placeholder="E-mail" style={{"backgroundColor":"#FAFFBD"}} id="email" ref="email" onChange={this.handleChange.bind(this, "email")} defaultValue={this.state.fields["email"]}/>
										<span style={{color: "red"}}>{this.state.errors["email"]}</span>
									</div>
									<div className="form-group">
										<input type="password" className="form-control" name="password" placeholder="Password" style={{"backgroundColor":"#FAFFBD"}} id="password" ref="password" onChange={this.handleChange.bind(this, "password")} defaultValue={this.state.fields["password"]}/>
										<small>Password must be at least 8 characters long</small>
										<span style={{color: "red"}}>{this.state.errors["password"]}</span>
									</div>
									<div className="form-group row">
										<div className="col-md-6 col-8">
											<div className="checkbox">
												 <label htmlFor="remember-me"><span><input id="remember-me" name="remember-me" type="checkbox"/></span> <span>Remember me</span> </label>
											</div>
										</div>
										<div className="col-md-6 col-4 text-right">
											<button type="submit" className="btn btn-primary" id="loginSubmit">
												Login
											</button>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-12 p-0">
											<Link to={"/user/forgot-password"} className="btn btn-link">Forgot Your Password?</Link>
											<Link to="#" className="btn btn-link">Didn't receive confirmation instructions?</Link>
										</div>
									</div>	
									<i className="fas fa-unlock"></i>
								</form>	
							</div>
						</div>		
					</div>
				</div>
			</React.Fragment>
		)
	}

	renderSignUpForm() {
		return(
			<React.Fragment>
				<div className="col-md-6 col-sm-6 col-lg-5 col-xl-5 px-0">
					<div className="gray-bg h-100">
						<h5>Sign up</h5>
						<form onSubmit={this.signUpForm.bind(this)} id="sign_up_user" className="mt-4">
							<span style={{color: "red"}}>{this.state.signup_error_message}</span>
							<div className="form-group">
								<input type="email" className="form-control" name="email" placeholder="E-mail" ref="user_email" id="user_email" onChange={this.handleChange.bind(this, "user_email")} defaultValue={this.state.fields["user_email"]}/>
								<span style={{color: "red"}}>{this.state.errors["user_email"]}</span>
							</div>
							<div className="form-group">
								<input type="password" className="form-control" name="password" placeholder="Password" ref="encrypted_password" id="encrypted_password" onChange={this.handleChange.bind(this, "encrypted_password")} defaultValue={this.state.fields["encrypted_password"]}/>
								<span style={{color: "red"}}>{this.state.errors['encrypted_password']}</span>
							</div>
							<div className="form-group">
								<input type="password" className="form-control" name="password" placeholder="Password Confirmation" ref="conf_password" id="conf_password" onChange={this.handleChange.bind(this, "conf_password")} defaultValue={this.state.fields["conf_password"]}/>
								<span style={{color: "red"}}>{this.state.errors['conf_password']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="fname" placeholder="First Name" ref="first_name" id="first_name" onChange={this.handleChange.bind(this, "first_name")} defaultValue={this.state.fields["first_name"]}/>
								<span style={{color: "red"}}>{this.state.errors['first_name']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="mName" placeholder="Middle Name" id="middle_initial" ref="middle_initial" onChange={this.handleChange.bind(this, "middle_initial")} defaultValue={this.state.fields["middle_initial"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="lname" placeholder="Last Name" ref="last_name" id="last_name" onChange={this.handleChange.bind(this, "last_name")} defaultValue={this.state.fields["last_name"]}/>
								<span style={{color: "red"}}>{this.state.errors['last_name']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="company" placeholder="Company" ref="company" id="company" onChange={this.handleChange.bind(this, "company")} defaultValue={this.state.fields["company"]}/>
								<span style={{color: "red"}}>{this.state.errors['company']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="state" placeholder="State" ref="state" id="state" onChange={this.handleChange.bind(this, "state")} defaultValue={this.state.fields["state"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="city" placeholder="City" ref="city" id="city" onChange={this.handleChange.bind(this, "city")} defaultValue={this.state.fields["city"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="address" placeholder="Address" ref="address" id="address" onChange={this.handleChange.bind(this, "address")} defaultValue={this.state.fields["address"]}/>
								<span style={{color: "red"}}>{this.state.errors['address']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="zip" placeholder="Zip" ref="zip" id="zip" onChange={this.handleChange.bind(this, "zip")} defaultValue={this.state.fields["zip"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="number" placeholder="Phone Number" ref="phone" id="phone" onChange={this.handleChange.bind(this, "phone")} defaultValue={this.state.fields["phone"]}/>
								<span style={{color: "red"}}>{this.state.errors['phone']}</span>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="number" placeholder="Cell Phone Number" ref="cell_phone" id="cell_phone" onChange={this.handleChange.bind(this, "cell_phone")} defaultValue={this.state.fields["cell_phone"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="number" placeholder="Direct Phone Number" ref="direct_phone" id="direct_phone" onChange={this.handleChange.bind(this, "direct_phone")} defaultValue={this.state.fields["direct_phone"]}/>
							</div>
							<div className="form-group">
								<input type="text" className="form-control" name="fax" placeholder="Fax" ref="fax" id="fax" onChange={this.handleChange.bind(this, "fax")} defaultValue={this.state.fields["fax"]}/>
							</div>
							<div className="form-group text-right">
								<button type="submit" className="btn btn-primary" id="btnSignUp">Sign up</button>
							</div>
						</form>	
					</div>
				</div>
			</React.Fragment>
		)
	}

	render() {
		return(
			<React.Fragment>
			{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<section className="login-area my-5">		
							<div className="row"> 
								<div className="col-md-11">
									<div className="row">
										{this.renderLoginForm()}
										{this.renderSignUpForm()}
									</div>	
								</div>
							</div>		
						</section>
						</div>	
				</main>
			</React.Fragment>
		);
	}
}

export default Login