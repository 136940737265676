/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-09 18:50:28
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-10-19 17:33:34
 */
import React from 'react';
//import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import logo from '../../images/home-img/logo-small.png';
import searchIcon from '../../images/home-img/search.webp';
import userIcon from '../../images/home-img/user.webp';
import washPlus from '../../images/home-img/wash-plus-llc.webp';
import HeaderService from './../../services/HeaderService/HeaderService';
import { urlPath, SITE_URL } from '../../constants.js';
import TitleUnderline from '../TitleUnderline/TitleUnderline';
import TitleWithCircle from '../TitleWithCircle/TitleWithCircle';
import HeaderAccordion from '../HeaderAccordion/HeaderAccordion';
import './Header.style.css';
import { productHierarchy } from './categories.js';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
class UserHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuItems: [],
			fields: {},
			errors: {},
			error_message: '',
			signup_error_message: '',
			user_id: '',
			first_name: '',
			last_name: '',
			is_login: 0,
			is_logout: 0,
			company:'',
			company_logo:''
		};
		this.renderNavBar = this.renderNavBar.bind(this);
		this.renderIpadNavBar = this.renderIpadNavBar.bind(this);
		this.renderProductMenu = this.renderProductMenuTwo.bind(this);
		this.headerService = new HeaderService();
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
    if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
      this.setState({
        first_name: localStorage.getItem('techtop_user_first_name'),
      });
      this.setState({
        last_name: localStorage.getItem('techtop_user_last_name'),
      });
      this.setState({
        company: localStorage.getItem('techtop_user_company'),
      });
      this.setState({ company_logo: SITE_URL+'files/company_logo/'+localStorage.getItem('techtop_user_company_logo')});
      this.setState({ phone: localStorage.getItem('techtop_user_phone')});
      this.setState({ cell_phone: localStorage.getItem('techtop_user_cell_phone')});
      this.setState({ direct_phone: localStorage.getItem('techtop_user_direct_phone')});
      this.setState({ fax: localStorage.getItem('techtop_user_fax')});
      this.setState({ address: localStorage.getItem('techtop_user_address')});
      this.setState({ state: localStorage.getItem('techtop_user_state')});
      this.setState({ city: localStorage.getItem('techtop_user_city')});
      this.setState({ zip: localStorage.getItem('techtop_user_zip')});
      this.setState({ is_login: 1 });
    }

    this.headerService.getCategory().then((response) => {
      if (response.status === 200) {
        //console.log(response.data);
        this.setState({ menuItems: response.data.result });
      }
    });
    if (
      localStorage.getItem('show_message') != '' &&
      localStorage.getItem('show_message') === 1
    ) {
      $('#success_message').css('display', 'block');
      $('#success_message').html(localStorage.getItem('success_message'));
    } else {
      $('#success_message').css('display', 'none');
      $('#success_message').html('');
    }

    setTimeout(
      function () {
        //this.setState({success_message:''});
        //$('#success_message').css('display','none');
        localStorage.setItem('show_message', 0);
        localStorage.setItem('success_message', '');
      }.bind(this),
      10000
    );
  }

  logoutRedirect() {
    if (this.state.is_logout === 1) {
      return <Redirect to="/" />;
    }
  }


  logout() {
    this.userService.logout().then((response) => {
      console.log(response);
      this.setState({ is_logout: 1 });
      this.setState({ is_login: 0 });
    });
  }



	renderProductMenuTwo() {
    const nemaName = productHierarchy[0].name;
    const iecName = productHierarchy[1].name;
    const nemaCategories = productHierarchy[0].categories;
    const iecCategories = productHierarchy[1].categories;
    const accesoriesName = productHierarchy[2].name;
    const accesoriesCategories = productHierarchy[2].categories;

    return (
      <>
        <div className="header nema-col col-sm-7 col-12 px-0 d-flex flex-column justify-content-center align-items-center flex-nowrap py-3">
          <TitleUnderline linkTo={'#'} title={nemaName} />
          <div className="nema-cols d-flex row flex-nowrap align-items-start justify-content-center py-3 gap-4">
            {/* 1st sub column of nema */}
            <div className="first-sub-col header px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(0, Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <HeaderAccordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>

            {/* 2nd sub column of nema */}
            <div className="second-sub-col header first-sub-col px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <HeaderAccordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className="header col-sm-4 col-12 d-flex flex-column gap-4 justify-content-center align-items-center px-0 py-3">
          <div className="header px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={iecName} />
            <div className="d-flex iec-col align-items-start text-start justify-content-start row w-75 pt-3">
              {iecCategories.map((category) => {
                const key = category.name.trim().replace(/\s/g, '-');
                if (category.collapsable === true) {
                  return (
                    <HeaderAccordion
                      key={key}
                      name={category.name}
                      subItems={category.subCategories}
                      collapseId={`${key}`}
                    />
                  );
                } else {
                  return (
                    <TitleWithCircle
                      key={key}
                      title={category.name}
                      linkTo={category.permalink}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="header acce-col px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={accesoriesName} />
            <div className="d-flex align-items-start text-start justify-content-start">
              <ul className="list-unstyled h3 text-nowrap">
                {accesoriesCategories.map((subItem, index) => {
                  return (
                    <li key={index} className="list-unstyled">
                      <a href={subItem.permalink}>{subItem.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

	renderIpadNavBar() {
		return (
			<React.Fragment>
				<div className="header-right-div d-none d-md-block d-sm-block d-xl-none d-lg-none">
					<ul>
						
						<li>
							<div className="dropdown my-account-btn">
								<button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<img src={userIcon} width="20" alt="User" /> My Account
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li><a className="dropdown-item" href="#">{this.state.company}</a></li>
									<li><a className="dropdown-item" href="/user/my-account">Account Info</a></li>
									<li><a className="dropdown-item" href="/user/order-history">Order History</a></li>
									<li><a className="dropdown-item" href="/user/open-orders">Open Orders </a></li>
									<li><a className="dropdown-item" href="/track-shipment">Tracking</a></li>
									<li><Link className="dropdown-item" to={"#"} onClick={this.logout}>Log Out</Link></li>
								</ul>
							</div>
						</li>
						<li><a href=""><img src={this.state.company_logo} width="50" alt="wash-plus-llc" /></a></li>
					</ul>
				</div>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
			</React.Fragment>
		);
	}

	renderMobileNavBar() {
		return (
			<React.Fragment>
				<div className="header-right-div d-block d-md-none"Name>
					<ul className="top-menu">
	
						<li>
							<div className="dropdown my-account-btn">
								<button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<img src={userIcon} width="20" alt="User" /> My Account
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li><a className="dropdown-item" href="#">Wash Plus LLC</a></li>
									<li><a className="dropdown-item" href="/user/my-account">Account Info</a></li>
									<li><a className="dropdown-item" href="/user/order-history">Order History</a></li>
									<li><a className="dropdown-item" href="/user/open-orders">Open Orders </a></li>
									<li><a className="dropdown-item" href="/track-shipment">Tracking</a></li>
									<li><Link className="dropdown-item" to={"#"} onClick={this.logout}>Log Out</Link></li>
								</ul>
							</div>
						</li>
						<li><a href=""><img src={this.state.company_logo} width="50" alt="wash-plus-llc" /></a></li>
					</ul>
				</div>
			</React.Fragment>
		);
	}

	renderDesktopNavBar() {
		return (
			<React.Fragment>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mx-auto nav-gradient">
						<li className="nav-item"> <Link className="nav-link active" data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false">Products</Link>
                    <div className="dropdown-menu header" id="dropdown">
                    <div className="products-dropdown d-flex flex-lg-row flex-column justify-content-center align-items-start gap-2 py-3 flex-wrap">
                      {this.renderProductMenuTwo()}
                    </div>
                  </div>
                    </li>
						<li className="nav-item"> <a href='/downloads' className="nav-link">Downloads</a> </li>
						<li className="nav-item"> <a href='/news' className="nav-link">Lets Talk Techtop</a> </li>
						<li className="nav-item"> <a href='/contact' className="nav-link">Contact Us</a> </li>
					</ul>
					
					<div className="header-right-div d-flex d-none d-md-block">
						<ul>
								
							<li>
								<div className="dropdown my-account-btn">
									<button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
										<img src={userIcon} width="20" alt="User" /> My Account
									</button>
									<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
										<li><a className="dropdown-item" href="#">{this.state.company}</a></li>
										<li><a className="dropdown-item" href="/user/my-account">Account Info</a></li>
										<li><a className="dropdown-item" href="/user/order-history">Order History</a></li>
										<li><a className="dropdown-item" href="/user/open-orders">Open Orders </a></li>
										<li><a className="dropdown-item" href="/track-shipment">Tracking</a></li>
										<li><Link className="dropdown-item" to={"#"} onClick={this.logout}>Log Out</Link></li>
									</ul>
								</div>
							</li>
							<li><a href=""><img src={this.state.company_logo} width="50" alt="wash-plus-llc" /></a></li>
						</ul>
					</div>
					
				</div>
			</React.Fragment>
		);
	}

	renderNavBar() {
		return (
			<React.Fragment>
				{ this.renderMobileNavBar() }
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container">
						<a className="navbar-brand" href={'/'}>
							<img src={logo} width="200" alt="Techtopind Electric Motors" />
						</a>
						
						{ this.renderIpadNavBar() }
						{ this.renderDesktopNavBar() }
					</div>
				</nav>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				<header>
					{this.renderNavBar()}
				</header>
			</React.Fragment>
		);
	}
}

export default UserHeader;
