/*
* @Author: Abhi Bhatt
* @Date:   2020-07-14 17:23:44
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:44
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';

class ResetPassword extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			fields: {},
            errors: {},
            success_redirect:0
		}
		this.resetPasswordForm = this.resetPasswordForm.bind(this);
		this.successRedirect = this.successRedirect.bind(this);
		this.userService = new UserService();
	}

	componentDidMount(){

	}

	resetPasswordForm(e) {
		let errors = {};
		e.preventDefault();
		const resetPasswordToken = this.props.match.params.resetPasswordToken;
		if(this.handleValidation()) {
			this.userService.resetPassword(resetPasswordToken,this.state.fields).then(response=>{
				if(response.status===200) {
					if(response.data.response==='fail'){
						errors["error_message"] = response.data.message;
					}
					else{
						$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
					}
					this.setState({errors: errors});
				}
				//console.log(response);
			});
		}
	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

	handleValidation() {
    	let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["encrypted_password"]){
        	formIsValid = false;
        	errors["encrypted_password"] = "Please enter your password.";
        }
        if(!fields["conf_password"]){
        	formIsValid = false;
        	errors["conf_password"] = "Please enter your password.";
        }
        if(fields['encrypted_password']!==fields['conf_password']){
        	formIsValid = false;
        	errors["conf_password"] = "Password not match.";
        }
        this.setState({errors: errors});
       	return formIsValid;
    }

    handleChange(field, e){     
       // e.preventDefault();    
        let fields = this.state.fields;
        if(field.indexOf('data') !== -1)
        {
        	var newField = field.split(".");
        	//console.log(newField[0]);
        	if(newField[0] in fields)
        	{
	            if(newField[1] in fields[newField[0]])
	            {
	                if(newField[2] in fields[newField[0]][newField[1]])
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	                else
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = '';
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	            }   
	            else
	            {
	                fields[newField[0]][newField[1]] = {};  
	                fields[newField[0]][newField[1]][newField[2]] = '';
	                fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                fields[field] = e.target.value;
	            }
	        }
	        else {
	            fields[newField[0]] = {};
	            fields[newField[0]][newField[1]] = {};
	            fields[newField[0]][newField[1]][newField[2]] = '';
	            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	            fields[field] = e.target.value;
	        }
	    }
	    else
	    {
	    	fields[field] = e.target.value;
	    }
	    this.setState({fields});
	}

	render() {
		return(
			<React.Fragment>
			{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
					<section className="new-area my-3">		
						<div className="row"> 
							<div className="col-md-12">
								<h5 style={{fontSize:"1.2em"}} className="pl-5">Change your password?</h5>
								<div className="col-md-6 offset-md-3">
								<span style={{color: "red"}}>{this.state.errors['error_message']}</span>
								<form onSubmit={this.resetPasswordForm.bind(this)}>
									<div className="form-group">
										<input type="password" className="form-control" name="password" placeholder="Password" ref="encrypted_password" id="encrypted_password" onChange={this.handleChange.bind(this, "encrypted_password")} defaultValue={this.state.fields["encrypted_password"]}/>
										<span style={{color: "red"}}>{this.state.errors['encrypted_password']}</span>
									</div>
									<div className="form-group">
										<input type="password" className="form-control" name="password" placeholder="Password Confirmation" ref="conf_password" id="conf_password" onChange={this.handleChange.bind(this, "conf_password")} defaultValue={this.state.fields["conf_password"]}/>
										<span style={{color: "red"}}>{this.state.errors['conf_password']}</span>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-primary">Change my password</button>
									</div>
									<div className="shared-links">
										<Link to={"/user/login"}>Sign in</Link>&nbsp;&nbsp;<Link to={"/user/login"}>Sign up</Link><br/>
										<Link to={"/user/confirmation"}>Didn't receive confirmation instructions?</Link>
									</div>		
								</form>
								</div>
							</div>
						</div>		
					</section>
					</div>	
				</main>
			</React.Fragment>
		);
	}

}

export default ResetPassword;