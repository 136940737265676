/*
* @Author: Abhi Bhatt
* @Date:   2020-06-09 18:13:45
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-10-09 11:50:04
*/
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Downloads from '../../pages/Downloads/Downloads';
import News from '../../pages/News/News';
import Category from '../../pages/Category/Category';
import Product from '../../pages/Product/Product';
import ProductInventory from '../../pages/Product/ProductInventory';
import Motor from '../../pages/Motor/Motor';
import MotorInventory from '../../pages/Motor/MotorInventory';
import MotorInventoryOld from '../../pages/Motor/MotorInventoryOld';
import Contact from '../../pages/Contact/Contact';
import ContactDetails from '../../pages/Contact/ContactDetails';
import Location from '../../pages/Location/Location';
import LocationDetails from '../../pages/Location/LocationDetails';
import CheckLogin from '../../pages/CheckLogin/CheckLogin';
import Login from '../../pages/User/Login';
import SignUp from '../../pages/User/SignUp';
import ForgotPassword from '../../pages/User/ForgotPassword';
import Confirmation from '../../pages/User/Confirmation';
import ResetPassword from '../../pages/User/ResetPassword';
import SearchMotor from '../../pages/Search/SearchMotor';
import SearchMotorInventory from '../../pages/Search/SearchMotorInventory';
import ProtectedRoute from '../../ProtectedRoute';
import TrackShipment from '../../pages/Shipment/TrackShipment';
import TrackShipmentOld from '../../pages/Shipment/TrackShipmentOld';
import MyAccount from '../../pages/User/MyAccount';
import OrderHistory from '../../pages/User/OrderHistory';
import OpenOrders from '../../pages/User/OpenOrders';

function Main() {
	return (
		<main>
			<Switch>
				<Route exact path='/' component={Home} />
				<Route exact path='/downloads' component={Downloads} />
				<Route exact path='/news' component={News} />
				<Route exact path="/categories/:permalink" component={Category} />
				<Route exact path="/products" component={CheckLogin} />
				<Route exact path="/products/:permalink" component={Product} />
				<ProtectedRoute exact path="/products/:permalink/inventory" component={ProductInventory} />
				<Route exact path="/motors/:part_number" component={Motor} />
				<Route exact path="/motors/:part_number/inventory" component={MotorInventory} />
				<Route exact path='/contact' component={Contact} />
				<Route exact path='/contact/:route' component={ContactDetails} />
				<ProtectedRoute exact path='/locations' component={Location} />
				<Route exact path='/location/:permalink' component={LocationDetails} />
				<Route exact path='/user/login' component={Login} />
				<Route exact path='/user/sign-up' component={SignUp} />
				<Route exact path='/user/forgot-password' component={ForgotPassword} />
				<Route exact path='/user/confirmation' component={Confirmation} />
				<Route exact path='/user/confirmation/:confirmationToken' component={Confirmation} />
				<Route exact path="/user/reset-password/:resetPasswordToken" component={ResetPassword} />
				<Route exact path="/search-motor/" component={SearchMotor} />
				<Route exact path="/search-motor-inventory/" component={SearchMotorInventory} />
				<Route exact path="/motors/:part_number/inventory/old" component={MotorInventoryOld} />
				<Route exact path='/track-shipment' component={TrackShipment} />
				<Route exact path='/track-shipment-old' component={TrackShipmentOld} />
				<ProtectedRoute exact path="/user/my-account" component={MyAccount} />
				<ProtectedRoute exact path="/user/order-history" component={OrderHistory} />
				<ProtectedRoute exact path="/user/open-orders" component={OpenOrders} />
			</Switch>
		</main>
	);

}


export default Main;