/*
* @Author: Randall Vargas Li
* @Date:   2024-04-26
* @Last Modified by:   Randall Vargas Li
* @Last Modified time: 2024-04-26
*/
import axios from 'axios';
import { API_URL } from '../../constants.js';

class VoltageService {
    async getVoltageDetails(voltage_code) {
      const url = `${API_URL}/get-voltage-details/${voltage_code}/`;
      return axios.get(url)
        .then(response => {
          if (response.data.success && response.data.voltages) {
            return response.data.voltages; // Devuelve solo la lista de voltajes
          } else {
            throw new Error('Failed to fetch voltage details');
          }
        })
        .catch(error => {
          console.error("Error fetching voltage details:", error);
          return []; // Devuelve un array vacío en caso de error
        });
    }
  }
  
  export default VoltageService;