/*
* @Author: Randall Vargas Li
* @Date:   2024-04-26
* @Last Modified by:   Randall Vargas Li
* @Last Modified time: 2024-04-26
*/
import axios from 'axios';
import { API_URL } from '../../constants.js';

class PartService {
  async getPartDetail(part_number) {
    const url = `${API_URL}/get-part-details/${part_number}/`;
    return axios.get(url)
      .then(response => response.data)
      .catch(error => {
        console.error("Error fetching part details:", error);
        return { error };
      });
  }
}

export default PartService;
