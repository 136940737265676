/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-16 14:45:13
 * @Last Modified by:   Abhi Bhatt
 * @Last Modified time: 2020-06-17 18:14:17
 */
import React from 'react';
import { Link } from 'react-router-dom';
import NewsService from '../../services/NewsService/NewsService';

const renderHTML = (rawHTML: string) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news_data: [],
    };
    this.newsService = new NewsService();
    this.renderNewsItems = this.renderNewsItems.bind(this);
  }

  componentDidMount() {
    this.newsService.getNews().then((response) => {
      if (response.status === 200) {
        this.setState({ news_data: response.data.result.news });
      }
    });
  }

  renderNewsItems() {
    let news_data = this.state.news_data;
    if (news_data && news_data.length > 0) {
      return Object(news_data).map(function (value, key) {
        return (
          <React.Fragment key={key}>
            <div className="row py-3">
              <div className="col-md-12 pt-5 pb-3">
                <div className="newsBox">
                  {renderHTML(value.fields.text)}
                  <h5>April 08, 2016 14:30 </h5>
                </div>
              </div>
            </div>
            <hr />
          </React.Fragment>
        );
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="news-area mt-2">
              <div className="row">
                <div className="col-md-12 pt-3 pb-5">
                  {this.renderNewsItems()}
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default News;
